import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main, buttonBack } from '../content/transport'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import Flag from '../assets/Flag'
// import BreifcaseIcon from '../assets/BreifcaseIcon'
import TruckIcon from '../assets/TruckIcon'
import ButtonForm from '../components/ButtonForm'
import { pageName } from '../content/_dictonary'

const Transport = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image, imageGlobal, imageServices } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/transport.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      imageGlobal: file(absolutePath: { regex: "/images/intros/global.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
      imageServices: file(
        absolutePath: { regex: "/images/intros/services.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, formats: [JPG])
        }
      }
    }
  `)

  const links = [
    {
      slug: 'global',
      name: pageName.global[lang],
      image: imageGlobal,
      icon: () => <Flag />,
    },
    // {
    //   slug: 'transport-branze',
    //   name: lang === 'en' ? 'Transport for Industries' : 'Transport dla Branż',
    //   image: imageIndustries,
    //   icon: () => <BreifcaseIcon />,
    // },
    {
      slug: 'services',
      name: pageName.services[lang],
      image: imageServices,
      icon: () => <TruckIcon />,
    },
  ]

  const buttonSecond = {
    text: {
      en: 'Quote transport',
      es: 'Evaluar el transporte',
      ro: 'Cota de transport',
    },
    action: () => setFormOpen(true),
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/transport.jpg'}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, buttonSecond }}
        image={image.childImageSharp}
        h={1}
      />
      <Main data={main} />
      <Tiles data={links} large center />
      <ButtonForm data={buttonBack} />
    </Layout>
  )
}

export default Transport
