export const seo = {
  url: 'transport',
  title: {
    en: 'Transport | Services | Types | Countries',
    es: 'Transporte | Servicios | Tipos | Los paises',
    ro: 'Transport | Servicii | Tipuri | tari',
  },
  desc: {
    en: 'Learn about the types of transport, comprehensive transport services in various industries. Check what transport looks like in European countries.',
    es: 'Descubre los tipos de transporte, servicios integrales de transporte en spanersas industrias. Compruebe cómo es el transporte en los países europeos.',
    ro: 'Aflați despre tipurile de transport, servicii complete de transport în spanerse industrii. Verificați cum arată transportul în țările europene.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'transport types',
    'transport services',
    'transport in europe',
    'transport in countries',
  ],
}

export const intro = {
  title: {
    en: 'Transport',
    es: 'Transporte',
    ro: 'Transport',
  },
  desc: {
    en: 'Learn about the types of transport, comprehensive transport services in various industries. Check what transport looks like in European countries.',
    es: 'Descubre los tipos de transporte, servicios integrales de transporte en spanersas industrias. Compruebe cómo es el transporte en los países europeos.',
    ro: 'Aflați despre tipurile de transport, servicii complete de transport în spanerse industrii. Verificați cum arată transportul în țările europene.',
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citește mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'Transport Omida VLS - Polish transport company. Take advantage of TFL services and deliver your transport to the destination.',
        es: 'Transporte Omida VLS - empresa de transporte polaca. Aproveche los servicios de TFL y entregue su transporte en el destino.',
        ro: 'Transport Omida VLS - Companie de transport poloneză. Profită de serviciile TFL și livrează-ți transportul la destinație.',
      },
      texts: [
        {
          en: `<span>Omida VLS is a Polish transport company. We deal with the transport of goods by road, rail and intermodal transport. As part of our services, we organize a multitude of different types of transport, incl. <strong>full truckload transport</strong> and <strong> less than truckload transport </strong>.</span>`,
          es: `<span>Omida VLS es una empresa de transporte polaca. Nos ocupamos del transporte de mercancías por carretera, ferrocarril y transporte intermodal. Como parte de nuestros servicios, organizamos una multitud de diferentes tipos de transporte, incl. <strong>transporte de carga completa</strong> y <strong>transporte de carga parcial</strong>.</span>`,
          ro: `<span>Omida VLS este o companie de transport poloneză. Ne ocupăm de transportul de mărfuri rutier, feroviar și transport intermodal. Ca parte a serviciilor noastre, organizăm o multitudine de tipuri diferite de transport, inclusiv <strong>transport complet cu camionul</strong> și <strong> mai puțin decât transportul cu camionul </strong>.</span>`,
        },
        {
          en: `<span>Our branches are located in over 30 cities all over Poland. We have <a href="https://omida.eu/logistics-centre/">modern warehouses</a> with an area of ​200,000 m² in key locations in Poland, supporting all logistics processes of our customers.</span>`,
          es: `<span>Nuestras sucursales están ubicadas en más de 30 ciudades de toda Polonia. Desde 2019, contamos con un <strong> almacén de clase A cerca de Varsovia</strong>, que se ocupa de todos los procesos logísticos de nuestros clientes.</span>`,
          ro: `<span>Filialele noastre sunt situate în peste 30 de orașe din toată Polonia. Din 2019, avem un <strong>depozit de clasă A lângă Varșovia</strong>, care se ocupă de toate procesele logistice ale clienților noștri.</span>`,
        },
        {
          en: `<span>Our strength is <strong> FTL-full truckload transport across Europe</strong>. This is where we are the best!</span>`,
          es: `<span>Nuestro punto fuerte es el <strong> transporte de camiones completos FTL en toda Europa</strong>. ¡Aquí es donde somos los mejores!</span>`,
          ro: `<span>Puterea noastră este <strong>transportul FTL cu camioane complete în toată Europa</strong>. Aici suntem cei mai buni!</span>`,
        },
        {
          en: `<span>Looking to transport goods in Europe? Choose Omida VLS. We focus on <strong> constant contact between the supervisor and the client and the team's specialization </strong> in serving a given industry!</span>`,
          es: `<span>¿Quiere transportar mercancías en Europa? Elija Logística Omida. ¡Nos enfocamos en el <strong> contacto constante entre el supervisor y el cliente y la especialización del equipo </strong> al servicio de una industria determinada!</span>`,
          ro: `<span>Căutați să transportați mărfuri în Europa? Alegeți Omida VLS. Ne concentrăm pe <strong> contactul constant între supervizor și client și specializarea echipei </strong> în deservirea unei anumite industrii!</span>`,
        },
      ],
    },
    {
      headline: {
        en: 'Spanision of transport into types, services and industries',
        es: 'spanisión del transporte en tipos, servicios e industrias',
        ro: 'spaniziunea transporturilor în tipuri, servicii și industrii',
      },
      texts: [
        {
          en: `<span>Transportation can be spanided into types related to different services. The most popular types of transport are FTL and LTL. Transport can also be categorized based on <strong>specific industries and the products</strong> they manufacture or the industries in which they are used. Also read about <strong>transport in different countries</strong>. Use the buttons below to go to the relevant subpages.</span>`,
          es: `<span>El transporte se puede spanidir en tipos relacionados con diferentes servicios. Los tipos de transporte más populares son FTL y LTL. El transporte también se puede categorizar en función de <strong>industrias específicas y los productos</strong> que fabrican o las industrias en las que se utilizan. Lea también sobre <strong>transporte en diferentes países</strong>. Use los botones a continuación para ir a las subpáginas relevantes.</span>`,
          ro: `<span>Transportul poate fi împărțit în tipuri legate de diferite servicii. Cele mai populare tipuri de transport sunt FTL și LTL. Transportul poate fi, de asemenea, clasificat în funcție de <strong>industriile specifice și de produsele</strong> pe care le fabrică sau de industriile în care sunt utilizate. Citiți și despre <strong>transportul în diferite țări</strong>. Utilizați butoanele de mai jos pentru a accesa subpaginile relevante.</span>`,
        },
      ],
    },
  ],
}

export const buttonBack = {
  text: {
    en: 'Quote a transport',
    es: 'Evaluar el transporte',
    ro: 'Cotați un transport',
  },
  link: '/quote?form=open/',
}
